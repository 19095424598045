import React, { useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { useWindowWidth } from '../../../helpers';
import Heading from '../../../redesign/atoms/Heading';
import CategoriesTabs from '../../../redesign/CategoriesTabs';
import { styled } from "../../../stitches.config";
import Adslot from '../../Adslot';
import AdslotWithScrollWrapper from '../../AdslotWithScrollWrapper';
import Box from '../Box';
import arrowIcon from "../../../../src/assets/img/arrow-orange-sm.svg";
import ArticleList from "../../../redesign/components/ArticleList";

const DynamicContent = ({ articles }) => {
  return <>
    <ArticleList articles={articles} />
  </>
}
const StaticCategory = ({ props }) => {
  const windowWidth = useWindowWidth();

  return <>
    <div className="row">
      <div className="col-xs-12 col-md-6">
        <Box {...props}
          id="b3"
          type={windowWidth > 996 ? "BTextOnPicture" : "BPhotoOnTop"}
          settings={props.settings.b3}
          style={props.blockGroupHasBackground ? 'with-padding' : null}
          disableVote={false} />
      </div>
      <div className="col-xs-12 col-md-3">
        <Box {...props}
          id="b4"
          type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
          settings={props.settings.b4}
          classes=""
          style={props.blockGroupHasBackground ? 'with-padding' : null}
          showAuthor={true}
          disableVote={false} />
      </div>
      <div className="col-xs-12 col-md-3">
        <Box {...props}
          id="b5"
          type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
          settings={props.settings.b5}
          classes=""
          style={props.blockGroupHasBackground ? 'with-padding' : null}
          showAuthor={true}
          disableVote={false} />
      </div>
    </div>
    <div className="row">
      <div className="col-md-12 col-lg-9">
        <div className="row" staticHeight>
          <div className="col-xs-12 col-md-4">
            <Box {...props}
              id="b6"
              type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
              settings={props.settings.b6}
              classes=""
              style={props.blockGroupHasBackground ? 'with-padding' : null}
              disableVote={false} />
          </div>
          <div className="col-xs-12 col-md-4">
            <Box {...props}
              id="b7"
              type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
              settings={props.settings.b7}
              classes=""
              style={props.blockGroupHasBackground ? 'with-padding' : null}
              disableVote={false} />
          </div>
          <div className="col-xs-12 col-md-4">
            <Box {...props}
              id="b8"
              type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
              settings={props.settings.b8}
              classes=""
              style={props.blockGroupHasBackground ? 'with-padding' : null}
              disableVote={false} />
          </div>
        </div>
        <div className="row" staticHeight>
          <div className="col-xs-12 col-md-4">
            <Box {...props}
              id="b9"
              type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
              settings={props.settings.b9}
              classes=""
              style={props.blockGroupHasBackground ? 'with-padding' : null}
              disableVote={false} />
          </div>
          <div className="col-xs-12 col-md-4">
            <Box {...props}
              id="b10"
              type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
              settings={props.settings.b10}
              classes=""
              style={props.blockGroupHasBackground ? 'with-padding' : null}
              disableVote={false} />
          </div>
          <div className="col-xs-12 col-md-4">
            <Box {...props}
              id="b11"
              type={windowWidth > 996 ? "BPhotoOnTop" : "BPhotoOnLeft"}
              settings={props.settings.b11}
              classes=""
              style={props.blockGroupHasBackground ? 'with-padding' : null}
              disableVote={false} />
          </div>
        </div>
      </div>
      <div className="visible-lg-block col-lg-3">
        <Reklama>
          <AdslotWithScrollWrapper name={props.settings?.bAdslot?.name} width={300} />
        </Reklama>
      </div>
    </div>
  </>
}

const BCategory = (props) => {
  const [{ user }] = useAuth();
  const [categoryData, setCategoryData] = useState(null);
  const windowWidth = useWindowWidth();
  const isDesktop = windowWidth > 996;

  const tags = (props.settings?.b2?.tags ? props.settings.b2.tags : []);
  const tabs = tags.map(({ name, feed }) => ({
    name, feed
  }))
  const isSubscribe = !user || !user.settings.ignored_categories.includes(props.settings.personalization?.key || '')
  const link = (props.settings?.b2?.link ? props.settings.b2.link : "");

  if (!isSubscribe) {
    return <section className="container">
      <Heading title={props.settings.b1.title_normal} btnSubscribe={props.settings.personalization?.key !== undefined} subscribe={isSubscribe} subscribeSection={props.settings.personalization?.key} />
      Nie widzisz tej sekcji, ponieważ zdecydowałeś się ją ukryć. Kliknij w przycisk lub ustawienia profilu „Twojego naTemat", żeby ponownie widzieć artykuły z tego działu.
    </section>;
  }

  return (
    <section className="container" data-tb-region={props.blockSettings.tb_region}>
      {props.settings.b1?.title_normal ?
        <Heading title={props.settings.b1?.title_normal} showMore={false} url={props.settings.b2.link} btnSubscribe={props.settings.personalization?.key !== undefined} subscribe={isSubscribe} subscribeSection={props.settings.personalization?.key} style={'category-' + props.settings.b1?.style} />
        :
        <HeadingMargin></HeadingMargin>
      }
      {tags.length > 0 && <CategoriesTabs headerTitle={props.settings.b1?.title_normal ? props.settings.b1?.title_normal : "noTitle"} tabs={tabs} setCategoryData={setCategoryData} isMore isMobile={windowWidth <= 992} sectionLink={link} style={props.blockGroupHasBackground ? 'with-background' : null} />}
      {isDesktop && link && tags.length === 0 &&
        <LinkMore href={link}>
          Więcej
          {/*<img src={arrowIcon} className="arrowIcon" />*/}
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.67387 2.00977L13.6638 6.99972L8.67387 11.9897L7.68392 10.9997L10.9837 7.69992L0.408142 7.69992V6.29992L10.9841 6.29992L7.68392 2.99972L8.67387 2.00977ZM0.583142 7.52492L11.4062 7.52492L7.93141 10.9997L8.67387 11.7422L8.67397 11.7421L7.93158 10.9997L11.4064 7.52489H0.583313L0.583313 6.47492H0.583142V7.52492ZM7.93141 2.99972L11.4066 6.47489H11.4068L7.93158 2.99969L8.67394 2.25732L8.67387 2.25725L7.93141 2.99972Z" />
          </svg>
        </LinkMore>
      }

      {categoryData === null
        ? <StaticCategory props={props} />
        : <DynamicContent articles={categoryData} />
      }

      {!isDesktop && link &&
        <LinkMoreMobile href={link}>
          Więcej z tej kategorii
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.890625 5.01223L2.022 3.88086L7.99773 9.8566L13.9735 3.88086L15.1048 5.01223L7.99773 12.1193L0.890625 5.01223ZM13.9733 4.16387L14.8217 5.01227L7.9976 11.8364L7.99773 11.8365L14.822 5.01223L13.9735 4.1637L13.9733 4.16387ZM2.02183 4.16386L7.99744 10.1395L7.9976 10.1393L2.022 4.1637L2.02183 4.16386Z" />
          </svg>
        </LinkMoreMobile>
      }
    </section>
  );
};

const Reklama = styled('div', {
  '@desktop': {
    marginTop: '-23px'
  }
});

const HeadingMargin = styled('div', {
  '@desktop': {
    marginTop: '20px'
  }
});


const LinkMore = styled("a", {
  position: "absolute",
  top: "60px",
  transform: "translateY(-50%)",
  right: 0,
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "$primary",
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  paddingRight: "12px",

  "&:hover": {
    color: "$primary120",
    textDecoration: "none",
  },

  "&:active": {
    color: "$primary",
    textDecoration: "none",
  },

  "& .arrowIcon": {
    marginLeft: "6px",
  },

  "& svg": {
    marginLeft: "6px",
    position: "relative",
    top: "1px",
    "& path": {
      fill: "$primary",
    }
  },
});


const LinkMoreMobile = styled("a", {
  textAlign: "center",
  fontWeight: 600,
  fontSize: "18px",
  lineHeight: "24px",
  letterSpacing: "0.2px",
  color: "$primary",
  borderTop: "1px solid rgba(255, 255, 255, 0.1)",
  marginTop: 16,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&:hover": {
    color: "$primary120",
    textDecoration: "none",
  },

  "&:active": {
    color: "$primary",
    textDecoration: "none",
  },

  "& svg": {
    marginLeft: "9px",
    "& path": {
      fill: "$primary",
    }
  },
});

export default BCategory;